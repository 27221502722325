import React, {useEffect} from 'react';
import Main from './pages/main';
import { useDataStore } from './stores/bioStore';
import GlobalStyle from './globalStyles';
import ThemeProvider from './ThemeProvider';

const App: React.FC = () => {
  const {fetchData, isLoading} = useDataStore();

  useEffect(() => {
    fetchData();
    }, []);


  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <GlobalStyle />
      <ThemeProvider>
        <Main />
      </ThemeProvider>
    </div>
  );
};

export default App;