import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import MainHeader from '../../components/header/header';
import ToggleModeSwitch from '../../components/elements/toggle-mode-switch/Toggle-mode-switch';
import ToggleLangSwitch from '../../components/elements/toggle-lang-switch/toggle-lang-switch';

interface LayoutProps {
  children?: ReactNode
}

const Body = styled.div`
  display: grid;
  place-items: center; /* Center both horizontally and vertically */
  height: 68vh;
`;

const IndexLayout: React.FC<LayoutProps> = 
// React.memo(
  ({ children }) =>  {
  return (
    <body>
      <MainHeader>
        <ToggleLangSwitch  />
        <ToggleModeSwitch />
      </MainHeader>
      <Body>
        {children}
      </Body>
    </body>
  );
}
// );

export default IndexLayout;