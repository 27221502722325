import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Bahnschrift', 'DIN Alternate', 'Franklin Gothic Medium', 'Nimbus Sans Narrow', sans-serif-condensed, sans-serif;
    font-weight: normal;
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 300ms ease-in, color 300ms ease-in;
    --primary-colour: ${({ theme }) => theme.text};
    --hover-glow: ${({ theme }) => theme.hoverGlow};
    --hover-color: ${({ theme }) => theme.hoverColor};
    --hover-underscore: ${({ theme }) => theme.hoverUnderscore};
    --body-color: ${({ theme }) => theme.body};
    --recess-black: #0c0c0c;
    --hollow-color: ${({ theme }) => theme.hollowColor};
    --active-color: ${({ theme }) => theme.activeColor};
    --hollow-glow:${({ theme }) => theme.hollowGlow};
  }
`;
 
export default GlobalStyle;