import { create } from 'zustand'

interface languageStore {
  lang: 'Ru' | 'En';
  toggleLang: () => void;
}

const useLanguageStore = create<languageStore>((set) => ({
  lang: 'Ru',
  toggleLang: () => set((state) => ({ lang: state.lang === 'Ru' ? 'En' : 'Ru' })),
}));

export default useLanguageStore;