import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ToggleModeSwitch from '../elements/toggle-mode-switch/Toggle-mode-switch';
import LanguageRadio from '../language-radio/language-radio';

interface HeaderProps {
  children?: ReactNode;
}

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
    position: sticky;
    top: 0.5em;
    flex-wrap: wrap;

`;


const MainHeader: React.FC<HeaderProps> = ({ children }) =>  {


  return (
    <Header>
      { children }
    </Header>
  );
}

export default MainHeader;