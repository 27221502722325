import React from "react";
import IndexLayout from "../layouts/index-layout/index-layout";
import MainTablet from "../components/main-tablet/main-tablet";




const Main: React.FC = () =>  {
    
    return(
    <IndexLayout>
        <MainTablet />
    </IndexLayout>

    );
}

export default Main;