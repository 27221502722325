import { create } from 'zustand';
import * as YAML from 'yaml'; // Import YAML using named import

interface SubData {
  fio: string;
  prof: string;
  conts: string;
  ski: string;
  skills: string[];
}

interface Data {
  ru: SubData;
  en: SubData;
  contacts:{
    tg:string;
    email:string;
    linkedIn:string;
  }
}

interface DataStore {
  data: Data | null;
  isLoading: boolean;
  fetchData: () => Promise<void>;
}

export const useDataStore = create<DataStore>((set) => ({
  data: null,
  isLoading: true,
  fetchData: async () => {
    try {
      const response = await fetch('/static/bio.yaml');
      const yamlData = await response.text();
      const parsedData = YAML.parse(yamlData) as Data; // Parse YAML data as Data type
      set({ data: parsedData, isLoading: false }); // Update state with parsed data
    } catch (error) {
      console.error('Error fetching data:', error);
      set({ isLoading: false }); // Set loading state to false even if an error occurs
    }
  },
}));