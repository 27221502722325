import React,  { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import useThemeStore from './stores/themeStore'
import { lightTheme, darkTheme } from './themes';

interface ProviderProps {
    children?: ReactNode
  }

const ThemeProvider: React.FC<ProviderProps> = ({ children }) => {
  const { theme } = useThemeStore();

  const selectedTheme = theme === 'light' ? lightTheme : darkTheme;
  return (
    <StyledThemeProvider theme={selectedTheme}>
      {children}
    </StyledThemeProvider>
  );
};

export default ThemeProvider;