import styled from "styled-components";
import { useState } from "react";
import { ChangeEvent } from "react";
import useLanguageStore from "../../../stores/languageStore";

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 1px;
  cursor: pointer;
`;

const Switch = styled.div`
position: relative;
  width: 60px;
  height: 28px;
  background: #030303;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;
  z-index: 2;
  box-shadow: var(--hollow-glow);
  background: var(--hollow-color);

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: #de5843;
    transform: translate(0, -50%);
  }
  transform: scale(0.68);

`;

const Input = styled.input`
  display: none;

  &:checked + ${Switch} {
    &:before {
      transform: translate(32px, -50%);
    }
  }
`;
interface LangProps {
  hasShadow: boolean;
}
const Lang = styled.label<LangProps>`
  position: relative; 
    transition: 300ms all;  
    display: inline-block;
    background-color: var(--body-color) ;
    color: var(--primary-colour);
    border-radius: 50%; /* Ensure it's a circle */
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    &:after {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 0.68em;
    height: 0.68em;
    border-radius: 50%;
    top: 100%;
    left: 75%;
    background: var(--recess-black);
    transform: translate(0, -50%);
    ${(props) => (props.hasShadow ? `
      box-shadow: var(--hover-glow);
      background: var(--active-color);
      `
      : "box-shadow:'none'" )
    }

  }
  `;

export default function ToggleLangSwitch(){

  const { lang, toggleLang }  = useLanguageStore();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleLang();
  }

  return (      
    <Label>
      <Lang hasShadow={lang === 'Ru'}>Ru</Lang>
      <Input checked={lang==='En'} type="checkbox" onChange={handleChange} />
      <Switch />
      <Lang hasShadow={lang==='En'}>En</Lang>
    </Label>
  );
};