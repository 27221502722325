import React from 'react';
import styled from 'styled-components';

interface BinProps{
    tags: string[];
    head: string;
}
const BinContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    max-width: 200px;
`

const TagCasing = styled.div`
  margin-right: 1em;
  font-size: 14px;
`

const TagHead = styled.div`
    font-size: 21px;
    font-weight: bold;
`

const Overhead =styled.div`
    display:flex;
    flex-direction: column;
    align-content: left;
`

const TagBin: React.FC<BinProps> = ({tags, head}) =>{


    return(
        <Overhead>
            <TagHead>{head}</TagHead>
            <BinContainer>
                {tags.map((item, index) => (
                    <TagCasing>{item}</TagCasing>
                    ))}
            </BinContainer>
        </Overhead>
    );
}

export default TagBin