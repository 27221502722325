import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDataStore } from '../../stores/bioStore';
import useLanguageStore from "../../stores/languageStore";
import TagBin from '../elements/tag-bin/tag-bin';

// Define the styled component
const TabletContainer = styled.div`
    padding: 10px;
    width: 45vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    @media only screen and (max-width: 900px) {
        width: 78vw;
}
`;

const NameTag = styled.b`
    font-size:35px;
    justify-content: center;
`;


const ProficencyTag = styled.div`
    font-size:21px;
    justify-content: center;
    font-weight: light;

`;

const PointTag = styled.b`
    font-size:21px;
    justify-content: center;
    margin-top: 0.67em;
`;


const ContactsContainer = styled.div`
    width: max-content;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
`;

const ContactLabel = styled.div`
    display: inline-block;
    font-size: 14px;
`

const ContactsTag = styled.a`
    position: relative;
    color: #de5843;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;

    &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--primary-colour);
    transition: width 0.3s ease-in-out;
    }

    /* Apply animation on hover */
    &:hover::before {
        width: var(--hover-underscore);
    }

    &:hover {
        transition:all 450ms;
        color: var(--hover-color);
        text-shadow: 0px 0 0.5em rgba(210, 101, 0, 0.5);
    }
`;

const Separator = styled.hr`
    color: var(--primary-colour);
    border: 1px solid var(--primary-colour);
    width: 75%;
    display: inline-block;
    margin:0;
    align-self: flex-start;
    margin-bottom: 0.67em;
`;

// Define the component that uses the styled component
const MainTablet: React.FC = () => {
    const { data} = useDataStore();
    const{ lang } = useLanguageStore();
    if (!data) {
        return null; // Render nothing if data is null
    }
    const datum = lang === 'Ru' ? (data?.ru || {}) : (data?.en || {});
    const contacts = (data?.contacts || {})
    return( 
        <TabletContainer>
            <ContactsContainer>
            <NameTag>{datum.fio}</NameTag>
            <ProficencyTag>{datum.prof}</ProficencyTag>
            <Separator />
            <TagBin tags = {datum.skills} head = {datum.ski}/>
            <PointTag>{datum.conts}</PointTag>
            <ContactLabel>Telegram <ContactsTag href = {contacts.tg}  target="_blank">@{contacts.tg.slice(13)}</ContactsTag></ContactLabel>
            <ContactLabel>E-Mail <ContactsTag href = {"mailto:".concat('',contacts.email)}  target="_blank">{contacts.email}</ContactsTag></ContactLabel>
            <ContactLabel>LinkedIn <ContactsTag href = {contacts.linkedIn}  target="_blank">{contacts.linkedIn.slice(28)}</ContactsTag></ContactLabel>
            </ContactsContainer>

        </TabletContainer>
        );
};

export default MainTablet;