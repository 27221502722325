

export const lightTheme = {
    name: 'light',
    body: '#F8F8FF',
    text: '#606060',
    hoverColor: '#6F2C21',
    hoverGlow: '',
    hoverUnderscore:'100%',
    hollowColor:'#0c0c0c',
    hollowGlow: '',
    activeColor:'#b90000',
  };

export const darkTheme = {
    name: 'dark',
    body: '#000a12',
    text: '#606060',
    hoverColor: '#FDFBC5' ,
    hoverUnderscore:'0%',
    hoverGlow: '0 0 0.5em 0.23em rgba(210, 101, 0, 0.5), inset 0 0 1em 0.23em rgba(210, 101, 0, 0.5)' ,
    hollowGlow: '0 0 1em 0.6em rgba(210, 101, 0, 0.5)',
    hollowColor:'#FFDCA0',
    activeColor:'#FDFBC5',
  };