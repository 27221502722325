import styled from "styled-components";
import { ChangeEvent } from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import useThemeStore from "../../../stores/themeStore";

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 12px;
`;

const Switch = styled.div`
position: relative;
  width: 60px;
  height: 28px;
  background: var(--hollow-color);
  box-shadow: var(--hollow-glow);
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;
  z-index: 2;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: #de5843;
    transform: translate(0, -50%);
  }
  transform: scale(0.68);
`;

const Input = styled.input`
  display: none;
  &:checked + ${Switch} {
    &:before {
      transform: translate(32px, -50%);
    }
  }
`;




export default function ToggleModeSwitch(){

  const { theme, toggleTheme }  = useThemeStore();
  const handleChange = () => {
    
    toggleTheme();
    
  }

  return (
    <Label>
      <FaSun style = {{ zIndex: 3, opacity: 1, color:'var(--primary-colour)',  }}/>
      <Input checked={theme === 'dark'} type="checkbox" onChange={handleChange} />
      <Switch />
      <FaMoon style = {{ zIndex: 3, opacity: 1, color:'var(--primary-colour)' }} />
    </Label>
  );
};